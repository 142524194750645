export const USP_TEXT = {
  'usp.title': {
    'en-GB': 'What Makes Them Great?',
    'nl-NL': 'Wat maakt ze geweldig?',
  },
  'usp.freshness_guarantee.title': {
    'en-GB': 'Freshness Guarantee',
    'nl-NL': 'Versgarantie',
  },
  'usp.freshness_guarantee.description': {
    'en-GB': '7 day freshness or a free replacement',
    'nl-NL': '7 dagen versgarantie of een gratis nieuw boeket',
  },
  'usp.perfect_gift_companion.title': {
    'en-GB': 'Perfect Gift Companion',
    'nl-NL': 'De perfecte aanvulling van je verrassing',
  },
  'usp.perfect_gift_companion.description': {
    'en-GB': 'Card arrives in the same package',
    'nl-NL': 'Je kaart zit in dezelfde verpakking',
  },
  'usp.next_day_delivery.title': {
    'en-GB': 'Next day delivery',
    'nl-NL': 'Volgende dag bezorgd',
  },
  'usp.next_day_delivery.description': {
    'en-GB': 'Order by 9pm',
    'nl-NL': 'Bestel voor 21.00 uur',
  },
}
