export const UPCOMING_OCCASION_CARD_TEXT = {
  'find.st': { 'en-GB': 'st', 'nl-NL': 'e' },
  'find.nd': { 'en-GB': 'nd', 'nl-NL': 'e' },
  'find.rd': { 'en-GB': 'rd', 'nl-NL': 'e' },
  'find.th': { 'en-GB': 'th', 'nl-NL': 'e' },
  'find.personalised_cards_cristmas_all': {
    'en-GB': 'personalised-cards/christmas/all/',
    'nl-NL': 'wenskaarten/kerst/',
  },
  'find.personalised_cards_valentines_day_all': {
    'en-GB': 'personalised-cards/valentines-day/all/',
    'nl-NL': 'wenskaarten/valentijn/',
  },
  'find.personalised_cards_mothers_day_all': {
    'en-GB': 'personalised-cards/mothers-day/all/',
    'nl-NL': 'wenskaarten/moederdag/',
  },
  'find.personalised_cards_fathers_day_all': {
    'en-GB': 'personalised-cards/fathers-day/all/',
    'nl-NL': 'wenskaarten/vaderdag/',
  },
}

export const UPCOMING_OCCASION_CARD_TEST_CASES = [
  { key: 'find.st', locales: { 'en-GB': 'st', 'nl-NL': 'e' } },
  { key: 'find.nd', locales: { 'en-GB': 'nd', 'nl-NL': 'e' } },
  { key: 'find.rd', locales: { 'en-GB': 'rd', 'nl-NL': 'e' } },
  { key: 'find.th', locales: { 'en-GB': 'th', 'nl-NL': 'e' } },
  {
    key: 'find.personalised_cards_cristmas_all',
    locales: {
      'en-GB': 'personalised-cards/christmas/all/',
      'nl-NL': 'wenskaarten/kerst/',
    },
  },
  {
    key: 'find.personalised_cards_valentines_day_all',
    locales: {
      'en-GB': 'personalised-cards/valentines-day/all/',
      'nl-NL': 'wenskaarten/valentijn/',
    },
  },
  {
    key: 'find.personalised_cards_mothers_day_all',
    locales: {
      'en-GB': 'personalised-cards/mothers-day/all/',
      'nl-NL': 'wenskaarten/moederdag/',
    },
  },
  {
    key: 'find.personalised_cards_fathers_day_all',
    locales: {
      'en-GB': 'personalised-cards/fathers-day/all/',
      'nl-NL': 'wenskaarten/vaderdag/',
    },
  },
]
