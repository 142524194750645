import { Region } from '@moonpig/web-core-types'

export const SOLD_OUT_GALLERY_MESSAGE_TEXT = {
  'find.oops_these_items_are_out_of_stock': {
    'en-GB': 'Oops! These items are out of stock.',
    'nl-NL': 'Oeps! Deze artikelen zijn niet op voorraad.',
  },
  'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_heartfelt_flowers':
    {
      'en-GB':
        "Looks like we've sold out of these products, but you can still send heartfelt flowers!",
      'nl-NL':
        'Het lijkt erop dat deze producten zijn uitverkocht, maar je kunt wel een prachtige bos bloemen opsturen!',
    },
  'find.view_flowers': { 'en-GB': 'View Flowers', 'nl-NL': 'Bloemen bekijken' },
  'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_a_heartfelt_gift':
    {
      'en-GB':
        "Looks like we've sold out of these products, but you can still send a heartfelt gift!",
      'nl-NL':
        'Het lijkt erop dat deze producten zijn uitverkocht, maar je kunt wel een cadeau sturen!',
    },
  'find.view_gifts': {
    'en-GB': 'View Gifts',
    'nl-NL': 'Cadeaus bekijken',
  },
  'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_a_heartfelt_card':
    {
      'en-GB':
        "Looks like we've sold out of these products, but you can still send a heartfelt card!",
      'nl-NL':
        'Het lijkt erop dat deze producten zijn uitverkocht, maar je kunt wel een kaartje sturen!',
    },
  'find.view_cards': { 'en-GB': 'View Cards', 'nl-NL': 'Kaarten bekijken' },
  'find.flowers_and_plants_link': {
    'en-GB': ({ region }: { region: Region }) =>
      `/${region}/flowers-and-plants/`,
    'nl-NL': ({ region }: { region: Region }) =>
      `/${region}/bloemen-en-planten/`,
  },
  'find.gifts_link': {
    'en-GB': ({ region }: { region: Region }) => `/${region}/gifts/`,
    'nl-NL': ({ region }: { region: Region }) => `/${region}/cadeaus/`,
  },
  'find.cards_link': {
    'en-GB': ({ region }: { region: Region }) =>
      `/${region}/personalised-cards/`,
    'nl-NL': ({ region }: { region: Region }) => `/${region}/wenskaarten/`,
  },
}
export const SOLD_OUT_GALLERY_MESSAGE_TEST_CASES = [
  {
    key: 'find.oops_these_items_are_out_of_stock',
    locales: {
      'en-GB': 'Oops! These items are out of stock.',
      'nl-NL': 'Oeps! Deze artikelen zijn niet op voorraad.',
    },
  },
  {
    key: 'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_heartfelt_flowers',
    locales: {
      'en-GB':
        "Looks like we've sold out of these products, but you can still send heartfelt flowers!",
      'nl-NL':
        'Het lijkt erop dat deze producten zijn uitverkocht, maar je kunt wel een prachtige bos bloemen opsturen!',
    },
  },
  {
    key: 'find.view_flowers',
    locales: { 'en-GB': 'View Flowers', 'nl-NL': 'Bloemen bekijken' },
  },
  {
    key: 'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_a_heartfelt_gift',
    locales: {
      'en-GB':
        "Looks like we've sold out of these products, but you can still send a heartfelt gift!",
      'nl-NL':
        'Het lijkt erop dat deze producten zijn uitverkocht, maar je kunt wel een cadeau sturen!',
    },
  },
  {
    key: 'find.view_gifts',
    locales: { 'en-GB': 'View Gifts', 'nl-NL': 'Cadeaus bekijken' },
  },
  {
    key: 'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_a_heartfelt_card',
    locales: {
      'en-GB':
        "Looks like we've sold out of these products, but you can still send a heartfelt card!",
      'nl-NL':
        'Het lijkt erop dat deze producten zijn uitverkocht, maar je kunt wel een kaartje sturen!',
    },
  },
  {
    key: 'find.view_cards',
    locales: { 'en-GB': 'View Cards', 'nl-NL': 'Kaarten bekijken' },
  },
  {
    key: 'find.flowers_and_plants_link',
    locales: {
      'nl-NL': `/nl/bloemen-en-planten/`,
    },
    contextProps: {
      region: 'nl',
    },
  },
  {
    key: 'find.gifts_link',
    locales: {
      'nl-NL': `/nl/cadeaus/`,
    },
    contextProps: {
      region: 'nl',
    },
  },
  {
    key: 'find.cards_link',
    locales: {
      'nl-NL': `/nl/wenskaarten/`,
    },
    contextProps: {
      region: 'nl',
    },
  },
]
