import { createReminderHeadingText } from '@moonpig/web-shared-reminders'

export const FIND_TEXT = {
  'find.page_productdetails': {
    'en-GB': ({ title }: { title: string }) => `${title} | Moonpig`,
    'nl-NL': ({ title }: { title: string }) => `${title} | Greetz`,
  },
  'find.view_all': {
    'en-GB': 'View All',
    'nl-NL': 'Bekijk alles',
  },
  'find.see_more': { 'en-GB': 'See More', 'nl-NL': 'Bekijk meer' },
  'find.see_less': { 'en-GB': 'See less', 'nl-NL': 'Bekijk minder' },
  'find.view_category': {
    'en-GB': 'View Category',
    'nl-NL': 'Categorie bekijken',
  },
  'find.home': { 'en-GB': 'Home', 'nl-NL': 'Home' },
  'find.continue_creation_title': {
    'en-GB': 'Continue your Creation',
  },
  'find.continue_creation_item_alt': {
    'en-GB': (c: { itemTitle: string }) => `Your design for: ${c.itemTitle}`,
  },
  'find.recently_viewed': {
    'en-GB': 'Recently Viewed',
    'nl-NL': 'Recent bekeken',
  },
  'find.open_recently_viewed_products': {
    'en-GB': 'Open recently viewed products',
    'nl-NL': 'Recent bekeken producten openen',
  },
  'find.close_recently_viewed_products': {
    'en-GB': 'Close recently viewed products',
    'nl-NL': 'Recent bekeken producten sluiten',
  },
  'find.birthday': {
    'en-GB': 'Birthday',
    'nl-NL': 'Verjaardag',
  },
  'find.special_occasion': {
    'en-GB': 'special occasion',
    'nl-NL': 'speciale gelegenheid',
  },
  'find.fathers_day': {
    'en-GB': 'Father’s Day',
    'nl-NL': 'Vaderdag',
  },
  'find.christmas': {
    'en-GB': 'Christmas',
    'nl-NL': 'Kerstmis',
  },
  'find.mothers_day': {
    'en-GB': 'Mother’s Day',
    'nl-NL': 'Moederdag',
  },
  'find.valentines_day': {
    'en-GB': 'Valentine’s Day',
    'nl-NL': 'Valentijnsdag',
  },
  'find.anniversary': {
    'en-GB': 'Anniversary',
    'nl-NL': 'Jubileum',
  },
  'find.new_baby': {
    'en-GB': 'New Baby',
    'nl-NL': 'Geboorte',
  },
  'find.wedding': {
    'en-GB': 'Wedding',
    'nl-NL': 'Huwelijk',
  },
  'find.note': {
    'en-GB': 'Note',
    'nl-NL': 'Berichtje',
  },
  'find.someone_special': {
    'en-GB': 'Someone special',
    'nl-NL': 'Speciaal iemand',
  },
  'find.gallery_title_results_found': {
    'en-GB': 'results found',
    'nl-NL': 'resultaten gevonden',
  },
  'find.show': {
    'en-GB': 'Show ',
    'nl-NL': 'Toon ',
  },
  'find.toggle_rude': {
    'en-GB': 'Rude ',
    'nl-NL': 'Brutale ',
  },
  'find.rude': {
    'en-GB': 'Rude',
    'nl-NL': 'Brutaal',
  },
  'find.for': {
    'en-GB': 'for',
    'nl-NL': 'voor',
  },
  'find.pdp_card_cut_off': {
    'en-GB': 'Check our website for cut off times and delivery information.',
  },
  'find.dynamic_page_card_cut_off': {
    'en-GB': 'Personalise with photos and custom text.',
  },
  'find.dynamic_page_uk_card_cut_off': {
    'en-GB':
      'Personalise with photos and custom text. Check our website for cut off times and delivery information.',
  },
  'find.dynamic_page_gift_cut_off': {
    'en-GB': 'Next day delivery available.',
  },
  'find.dynamic_page_flower_cut_off': {
    'en-GB': 'Choose a delivery date that suits you.',
  },
  'find.products_you_may_also_like': {
    'en-GB': 'Products you may also like:',
    'nl-NL': 'Dit is misschien ook iets voor je:',
  },
  'find.we_have_0_search_results': {
    'en-GB': 'We have 0 search results',
    'nl-NL': 'We hebben 0 zoekresultaten',
  },
  'find.showing_n_results_for_term_in_dept': {
    'en-GB': (c: {
      totalCount: number
      term: string
      departmentName: string | undefined
    }) => {
      return `Showing <strong>${c.totalCount} results</strong> for <strong>&quot;${c.term}&quot;</strong> in <strong>${c.departmentName}</strong>.`
    },
    'nl-NL': (c: {
      totalCount: number
      term: string
      departmentName: string
    }) => {
      return `<strong>${
        c.totalCount
      } resultaten</strong> zichtbaar voor <strong>&quot;${
        c.term
      }&quot;</strong> in <strong>${getNlDepartment(
        c.departmentName,
      )}</strong>.`
    },
  },
  'find.showing_n_results_for': {
    'en-GB': (c: {
      totalCount: number
      departmentName: string | undefined
    }) => {
      return `Showing <strong>${c.totalCount} results</strong> for <strong>&quot;`
    },
    'nl-NL': (c: { totalCount: number; departmentName: string }) => {
      return `<strong>${c.totalCount} resultaten</strong> zichtbaar voor <strong>&quot;`
    },
  },
  'find.in_dept': {
    'en-GB': (c: {
      totalCount: number
      departmentName: string | undefined
    }) => {
      return `&quot;</strong> in <strong>${c.departmentName}</strong>.`
    },
    'nl-NL': (c: {
      totalCount: number
      term: string
      departmentName: string
    }) => {
      return `&quot;</strong> in <strong>${getNlDepartment(
        c.departmentName,
      )}</strong>.`
    },
  },
  'find.showing_n_results_in_dept': {
    'en-GB': (c: {
      totalCount: number
      departmentName: string | undefined
    }) => {
      return `Showing <strong>${c.totalCount} results</strong> in <strong>${c.departmentName}</strong>.`
    },
    'nl-NL': (c: { totalCount: number; departmentName: string }) => {
      return `<strong>${
        c.totalCount
      } resultaten</strong> zichtbaar in <strong>${getNlDepartment(
        c.departmentName,
      )}</strong>.`
    },
  },
  'find.in': {
    'en-GB': 'in',
    'nl-NL': 'in',
  },
  'find.search_results': {
    'en-GB': 'Search Results',
    'nl-NL': 'Zoekresultaten',
  },
  'find.view_results': {
    'en-GB': 'View Results',
    'nl-NL': 'Bekijk Resultaten',
  },
  'find.view_n_results': {
    'en-GB': (c: { resultsCount: number }) => {
      return c.resultsCount > 1
        ? `View ${Intl.NumberFormat('en-GB', { style: 'decimal' }).format(
            c.resultsCount,
          )} results`
        : `View ${Intl.NumberFormat('en-GB', { style: 'decimal' }).format(
            c.resultsCount,
          )} result`
    },
    'nl-NL': (c: { resultsCount: number }) => {
      return c.resultsCount > 1
        ? `Bekijk ${Intl.NumberFormat('nl-NL').format(
            c.resultsCount,
          )} resultaten`
        : `Bekijk ${Intl.NumberFormat('nl-NL').format(
            c.resultsCount,
          )} resultaat`
    },
  },
  'find.tab_carousel_title': {
    'en-GB': (c: { moduleTitle: string }) => {
      return `${c.moduleTitle} products`
    },
    'nl-NL': (c: { moduleTitle: string }) => {
      return `${c.moduleTitle} producten`
    },
  },
  'find.filter_and_sort': {
    'en-GB': 'Filters & Sort',
    'nl-NL': 'Filteren & Sorteren',
  },
  'find.filters': {
    'en-GB': 'Filters',
    'nl-NL': 'Filters',
  },
  'find.filter_menu': {
    'en-GB': 'Filters Menu',
    'nl-NL': 'Filtermenu',
  },
  'find.all_filters': {
    'en-GB': 'All filters',
    'nl-NL': 'Alle filters',
  },
  'find.search_filters': {
    'en-GB': 'Search filters',
    'nl-NL': 'Zoeken filters',
  },
  'find.dont_see_your_filter': {
    'en-GB': "Don't see your filter? ",
    'nl-NL': 'Zie je je filter niet? ',
  },
  'find.search_for_x_instead': {
    'en-GB': (x: { searchTerm: string }) => {
      return `Search for ${x.searchTerm} instead`
    },
    'nl-NL': (x: { searchTerm: string }) => {
      return `Zoek even op ${x.searchTerm}`
    },
  },
  'find.open_category': {
    'en-GB': (x: { label: string }) => {
      return `open category ${x.label}`
    },
    'nl-NL': (x: { label: string }) => {
      return `categorie openen ${x.label}`
    },
  },
  'find.close_category': {
    'en-GB': (x: { label: string }) => {
      return `close category ${x.label}`
    },
    'nl-NL': (x: { label: string }) => {
      return `categorie sluiten ${x.label}`
    },
  },
  'find.select_category': {
    'en-GB': 'select category',
    'nl-NL': 'categorie kiezen',
  },
  'find.deselect_category': {
    'en-GB': 'de-select category',
    'nl-NL': 'categorie niet meer kiezen',
  },
  'find.dismiss_search': {
    'en-GB': 'dismiss search',
    'nl-NL': ' zoeken overslaan',
  },
  'find.n_more_filters': {
    'en-GB': (c: { filterName: string; moreCount: number }) => {
      return `${c.filterName} and ${c.moreCount} more`
    },
    'nl-NL': (c: { filterName: string; moreCount: number }) => {
      return `${c.filterName} en ${c.moreCount} meer`
    },
  },
  'find.n_filters_applied': {
    'en-GB': (c: { filterCount: number }) => {
      return c.filterCount > 1
        ? `${c.filterCount} filters applied`
        : `${c.filterCount} filter applied`
    },
    'nl-NL': (c: { filterCount: number }) => {
      return c.filterCount > 1
        ? `${c.filterCount} filters toegepast`
        : `${c.filterCount} filter toegepast`
    },
  },
  'find.view_in': {
    'en-GB': (c: { isZeroResults: boolean | undefined }) => {
      return `${c.isZeroResults ? 'View in ' : 'Also view in '}`
    },
    'nl-NL': (c: { isZeroResults: boolean | undefined }) => {
      return `${c.isZeroResults ? 'Bekijk in ' : 'Bekijk ook in '}`
    },
  },
  'find.or_in': {
    'en-GB': 'or in ',
    'nl-NL': 'of in ',
  },
  'find.open_filters': {
    'en-GB': 'Open filters',
    'nl-NL': 'Filters openen',
  },
  'find.close_filters_menu': {
    'en-GB': 'Close filters menu',
    'nl-NL': 'Filtermenu sluitten',
  },
  'find.filters_menu_panel.close': {
    'en-GB': (title: string) => `Close ${title} menu`,
    'nl-NL': /* istanbul ignore next */ (title: string) =>
      `Menu ${title} sluiten`,
  },
  'find.filters_menu_panel.open': {
    'en-GB': (title: string) => `Open ${title} menu`,
    'nl-NL': /* istanbul ignore next */ (title: string) =>
      `Menu ${title} openen`,
  },
  'find.scroll_to_the_top_of_the_viewport': {
    'en-GB': 'Scroll to the top of the viewport',
    'nl-NL': 'Scrol naar de bovenkant van het kijkvenster',
  },
  'find.nba_filters': {
    'en-GB': 'Quick filters',
    'nl-NL': 'Snelle filters',
  },
  'find.selected_filters': {
    'en-GB': 'Selected filters',
    'nl-NL': 'Geselecteerde filters',
  },
  'find.popular_filters': {
    'en-GB': 'Popular filters',
    'nl-NL': 'Populaire filters',
  },
  'find.inverted_toggle_filter_label': {
    'en-GB': (c: { label: string }) => {
      return `No ${c.label.toLocaleLowerCase()}`
    },
    'nl-NL': (c: { group: string; label: string }) => {
      return `Geen ${c.label.toLocaleLowerCase()}`
    },
  },
  'find.remove_facet_group_filter_facet_label': {
    'en-GB': (c: { group: string; label: string }) => {
      return `Remove ${c.group} filter ${c.label}`
    },
    'nl-NL': (c: { group: string; label: string }) => {
      return `Verwijder ${c.group} filter ${c.label}`
    },
  },
  'find.apply_facet_group_filter_facet_label': {
    'en-GB': (c: { group: string; label: string }) => {
      return `Apply ${c.group} filter ${c.label}`
    },
    'nl-NL': (c: { group: string; label: string }) => {
      return `${c.group} filter ${c.label} toepassen`
    },
  },
  'find.basket': {
    'en-GB': 'Basket',
    'nl-NL': 'Winkelmandje',
  },
  'find.clear_selection': {
    'en-GB': 'clear selection',
    'nl-NL': 'selectie wissen',
  },
  'find.product_count': {
    'en-GB': (c: { productCount: number }) => {
      return `Product Count: ${c.productCount}`
    },
    'nl-NL': (c: { productCount: number }) => {
      return `Producttelling: ${c.productCount}`
    },
  },
  'find.search_term': {
    'en-GB': (c: { searchTerm: string }) => {
      return `Search Term: ${c.searchTerm}`
    },
    'nl-NL': (c: { searchTerm: string }) => {
      return `Zoekterm: ${c.searchTerm}`
    },
  },
  'find.promotion_id': {
    'en-GB': (c: { promotionId: string | undefined }) => {
      return `Promotion Id: ${c.promotionId}`
    },
    'nl-NL': (c: { promotionId: string | undefined }) => {
      return `Promotie-id: ${c.promotionId}`
    },
  },
  'find.play_video': {
    'en-GB': 'Play video',
    'nl-NL': 'Video afspelen',
  },
  'find.load_more': {
    'en-GB': 'Load More',
    'nl-NL': 'Meer laden',
  },
  'find.loading_more': {
    'en-GB': 'Loading More',
    'nl-NL': 'Meer laden',
  },
  'find.loading': {
    'en-GB': 'Loading',
    'nl-NL': 'Bezig met laden',
  },
  'find.oops': {
    'en-GB': 'Oops!',
    'nl-NL': 'Oeps!',
  },
  'find.all': {
    'en-GB': (c: { label: string | undefined }) => {
      return `All ${c.label}`
    },
    'nl-NL': (c: { label: string | undefined }) => {
      return `Alle ${c.label}`
    },
  },
  'find.clear': {
    'en-GB': 'Clear',
    'nl-NL': 'Reset',
  },
  'find.sold_out': {
    'en-GB': 'This item is either sold out or no longer available.',
    'nl-NL': 'Dit artikel is uitverkocht of niet langer beschikbaar',
  },
  'find.its_not_you': {
    'en-GB': 'It’s not you, it’s us. Please try again!',
    'nl-NL': 'Het ligt niet aan jou, het ligt aan ons. Probeer het nog eens!',
  },
  'find.department': {
    'en-GB': (c: { departmentName: string }) => {
      return c.departmentName
    },
    'nl-NL': (c: { departmentName: string }) => {
      return getNlDepartment(c.departmentName)
    },
  },
  'find.popularity': {
    'en-GB': 'Popularity',
    'nl-NL': 'Populariteit',
  },
  'find.newness': {
    'en-GB': 'New',
    'nl-NL': 'Nieuw',
  },
  'find.price_high': {
    'en-GB': 'Price (high to low)',
    'nl-NL': 'Prijs (aflopend)',
  },
  'find.price_low': {
    'en-GB': 'Price (low to high)',
    'nl-NL': 'Prijs (oplopend)',
  },
  'find.sort_by': {
    'en-GB': 'Sort by',
    'nl-NL': 'Sorteren op',
  },
  'find.please_select': {
    'en-GB': 'Please select',
    'nl-NL': 'Maak je keuze',
  },
  'find.sort': {
    'en-GB': 'Sort',
    'nl-NL': 'Sorteren',
  },
  'find.reminder_view_all_cta': {
    'en-GB': 'See All Reminders',
    'nl-NL': 'Bekijk alle momenten',
  },
  'find.reminder_card.heading': {
    'en-GB': createReminderHeadingText('en-GB'),
    'nl-NL': createReminderHeadingText('nl-NL'),
  },
  'find.there_are_no_results_for': {
    'en-GB': 'There are no results for ',
    'nl-NL': 'We konden niets vinden voor ',
  },
  'find.all_cards': {
    'en-GB': 'All Cards',
    'nl-NL': 'Alle Kaarten',
  },
  'find.all_gifts': {
    'en-GB': 'All Gifts',
    'nl-NL': 'Alle Cadeaus',
  },
  'find.all_flowers': {
    'en-GB': 'All Flowers',
    'nl-NL': 'Alle Bloemen',
  },
  'find.all_cards_link': {
    'en-GB': 'personalised-cards/all/',
    'nl-NL': 'wenskaarten/alle/',
  },
  'find.all_gifts_link': {
    'en-GB': 'gifts/all/',
    'nl-NL': 'cadeaus/alle/',
  },
  'find.all_flowers_link': {
    'en-GB': 'flowers-and-plants/all/',
    'nl-NL': 'bloemen-en-planten/alle/',
  },
  'find.orders': {
    'en-GB': 'Orders',
    'nl-NL': 'Bestellingen',
  },
  'find.account': {
    'en-GB': 'Account',
    'nl-NL': 'Account',
  },
  'find.faqs': {
    'en-GB': 'FAQs',
    'nl-NL': 'FAQ',
  },
  'find.customer_service': {
    'en-GB': 'Customer Service',
    'nl-NL': 'Klantenservice',
  },
  'find.delivery': {
    'en-GB': 'Delivery',
    'nl-NL': 'Bezorging',
  },
  'find.sign_in': {
    'en-GB': 'Sign In',
    'nl-NL': 'Inloggen',
  },
  'find.start_shopping': {
    'en-GB': 'Start Shopping',
    'nl-NL': 'Verder rondkijken',
  },
  'find.moonpig_plus.added_to_basket': {
    'en-GB': 'Moonpig Plus added to your basket!',
    'nl-NL': 'Greetz Plus is toegevoegd aan je winkelmandje!',
  },
  'find.email_capture.sign_up': {
    'en-GB': 'Sign up',
    'nl-NL': 'TODO', // Not available in Greetz brand
  },
  'find.email_capture.email_address': {
    'en-GB': 'Email address',
    'nl-NL': 'TODO', // Not available in Greetz brand
  },
  'find.email_capture.unsubscribe': {
    'en-GB': 'You can unsubscribe at anytime.',
    'nl-NL': 'TODO', // Not available in Greetz brand
  },
  'find.email_capture.privacy_notice': {
    'en-GB': 'View our privacy policy',
    'nl-NL': 'TODO', // Not available in Greetz brand
  },
  'find.pick_up_where_you_left_off': {
    'en-GB': 'Pick Up Where You Left Off',
    'nl-NL': 'Ga verder waar je gebleven was',
  },
  'find.my_draft': {
    'en-GB': 'My Draft',
    'nl-NL': 'Mijn ontwerp',
  },
  'find.play-video-label': {
    'en-GB': 'Play video',
    'nl-NL': 'Video afspelen',
  },
  'find.video-label': {
    'en-GB': 'Video',
    'nl-NL': 'Video',
  },
  'find.search': {
    'en-GB': 'Search',
    'nl-NL': 'Zoeken',
  },
}

export const FIND_TEST_CASES = [
  {
    key: 'find.filters',
    locales: {
      'en-GB': 'Filters',
      'nl-NL': 'Filters',
    },
    contextProps: {
      filterCount: 3,
    },
  },
  {
    key: 'find.n_filters_applied',
    locales: {
      'en-GB': '3 filters applied',
      'nl-NL': '3 filters toegepast',
    },
    contextProps: {
      filterCount: 3,
    },
  },
  {
    key: 'find.n_filters_applied',
    locales: {
      'en-GB': '1 filter applied',
      'nl-NL': '1 filter toegepast',
    },
    contextProps: {
      filterCount: 1,
    },
  },
  {
    key: 'find.we_have_0_search_results_for_term_in_department',
    locales: {
      'en-GB':
        "<strong>We have 0 search results</strong> for 'saxophone' in Gifts.",
      'nl-NL':
        "<strong>We hebben 0 zoekresultaten</strong> voor 'saxophone' in Cadeaus.",
    },
    contextProps: {
      searchTerm: 'saxophone',
      departmentName: 'Gifts',
    },
  },
  {
    key: 'find.showing_n_results_for_term_in_dept',
    locales: {
      'en-GB':
        'Showing <strong>99 results</strong> for <strong>&quot;violin&quot;</strong> in <strong>Gifts</strong>.',
      'nl-NL':
        '<strong>99 resultaten</strong> zichtbaar voor <strong>&quot;violin&quot;</strong> in <strong>Cadeaus</strong>.',
    },
    contextProps: {
      totalCount: 99,
      departmentName: 'Gifts',
      term: 'violin',
    },
  },
  {
    key: 'find.view_in',
    locales: {
      'en-GB': 'Also view in ',
      'nl-NL': 'Bekijk ook in ',
    },
    contextProps: {
      isZeroResults: false,
    },
  },
  {
    key: 'find.view_in',
    locales: {
      'en-GB': 'View in ',
      'nl-NL': 'Bekijk in ',
    },
    contextProps: {
      isZeroResults: true,
    },
  },
  {
    key: 'find.view_n_results',
    locales: {
      'en-GB': 'View 55 results',
      'nl-NL': 'Bekijk 55 resultaten',
    },
    contextProps: {
      resultsCount: 55,
    },
  },
  {
    key: 'find.view_n_results',
    locales: {
      'en-GB': 'View 1 result',
      'nl-NL': 'Bekijk 1 resultaat',
    },
    contextProps: {
      resultsCount: 1,
    },
  },
  {
    key: 'find.product_count',
    locales: {
      'en-GB': 'Product Count: 17',
      'nl-NL': 'Producttelling: 17',
    },
    contextProps: {
      productCount: 17,
    },
  },
  {
    key: 'find.search_term',
    locales: {
      'en-GB': 'Search Term: bikes',
      'nl-NL': 'Zoekterm: bikes',
    },
    contextProps: {
      searchTerm: 'bikes',
    },
  },
  {
    key: 'find.promotion_id',
    locales: {
      'en-GB': 'Promotion Id: ABC123',
      'nl-NL': 'Promotie-id: ABC123',
    },
    contextProps: {
      promotionId: 'ABC123',
    },
  },
  {
    key: 'find.promotion_id',
    locales: {
      'en-GB': 'Promotion Id: undefined',
      'nl-NL': 'Promotie-id: undefined',
    },
    contextProps: {
      promotionId: undefined,
    },
  },
]

const getNlDepartment = (department: string) => {
  switch (department.toLowerCase()) {
    case 'gifts':
      return 'Cadeaus'
    case 'flowers':
      return 'Bloemen'
    case 'cards':
    default:
      return 'Kaarten'
  }
}
