export const DIGITAL_GIFT_REDEMPTION_TEXT = {
  'find.digital_gift_redemption_loading': {
    'en-GB': 'Your Surprise Is Almost Ready...',
    'nl-NL': 'Je verrassing is bijna klaar...',
  },
  'find.digital_gift_redemption_view_your_gift': {
    'en-GB': 'View Your Gift',
    'nl-NL': 'Bekijk je cadeau',
  },
  'find.digital_gift_redemption_unwrap_your_gift': {
    'en-GB': 'Unwrap Your Gift',
    'nl-NL': 'TODO',
  },
  'find.digital_gift_redemption_your_surprise_is_only_one_step_away': {
    'en-GB': 'Your Surprise Is Only One Step Away',
    'nl-NL': 'Je bent nog maar een stap verwijderd van je verrassing',
  },
  'find.digital_gift_redemption_something_went_wrong_its_not_you_its_us': {
    'en-GB': "Something Went Wrong, It's Not You, It's Us!",
    'nl-NL': 'Er ging iets mis, Het ligt niet aan jou, het ligt aan ons!',
  },
  'find.digital_gift_redemption_try_unwrapping_again': {
    'en-GB': 'Try Unwrapping Again',
    'nl-NL': 'Probeer het opnieuw',
  },
  'find.digital_gift_redemption_contact_customer_services': {
    'en-GB': 'Contact Customer Services',
    'nl-NL': 'Neem contact op met de klantenservice.',
  },
  'find.digital_gift_redemption_we_cannot_find_a_voucher_link_for_this_gift_card':
    {
      'en-GB': 'We cannot find a voucher link for this gift card',
      'nl-NL': 'TO DO',
    },
  'find.digital_gift_redemption_link_expired': {
    'en-GB': 'Unfortunately this link has expired',
    'nl-NL': 'TO DO',
  },
} as const
