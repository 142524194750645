import { CORE_TEXT, createLocaleTextHook } from '@moonpig/web-core-locale-text'
import { SOLD_OUT_GALLERY_MESSAGE_TEXT } from '../components/Gallery/soldOutGalleryMessage.locale'
import { PRODUCT_DETAILS_TEXT } from '../components/ProductDetails/productDetails.locale'
import { UPCOMING_OCCASION_CARD_TEXT } from '../components/UpcomingOccasionCard/upcomingOccasionCard.locale'
import { RECOMMENDATIONS_FOR_CUSTOMER_TEXT } from '../modules/RecommendationsForCustomer/recommendationsForCustomer.locale'
import { FIND_TEXT } from './localeText'
import { SHOP_FOR_REMINDER_TEXT } from '../modules/Placeholder/variant/ShopForReminder/shopForReminder.locale'
import { BUNDLES_TEXT } from '../modules/Bundles/bundles.locale'
import { CARD_OPTIONS_TEXT } from '../components/DigitalGiftCardOptions/digitalGiftCardOptions.locale'
import { PRODUCT_LOCATIONS_TEXT } from '../modules/ProductModules/ProductLocationsModule/productLocations.locale'
import { PRODUCT_SELLING_POINTS_TEXT } from '../modules/ProductModules/ProductSellingPointsModule/productSellingPoints.locale'
import { DIGITAL_GIFT_REDEMPTION_TEXT } from '../pages/DigitalGiftRedemptionPage/digitalGiftRedemption.locale'
import { RATING_TEXT } from '../modules/ProductModules/RatingModule/ratingModule.locale'
import { USP_TEXT } from '../modules/ProductModules/USPModule/USPmodule.locale'

const dictionary = {
  ...FIND_TEXT,
  ...RECOMMENDATIONS_FOR_CUSTOMER_TEXT,
  ...CORE_TEXT,
  ...SOLD_OUT_GALLERY_MESSAGE_TEXT,
  ...PRODUCT_DETAILS_TEXT,
  ...UPCOMING_OCCASION_CARD_TEXT,
  ...SHOP_FOR_REMINDER_TEXT,
  ...BUNDLES_TEXT,
  ...CARD_OPTIONS_TEXT,
  ...PRODUCT_LOCATIONS_TEXT,
  ...PRODUCT_SELLING_POINTS_TEXT,
  ...DIGITAL_GIFT_REDEMPTION_TEXT,
  ...RATING_TEXT,
  ...USP_TEXT,
}

export const useFindLocaleText = createLocaleTextHook({
  dictionary,
})
