export const PRODUCT_DETAILS_TEXT = {
  'find.frequently_bought_together': {
    'en-GB': 'Frequently Bought Together',
    'nl-NL': 'Vaak samen gekocht met',
  },
}

export const PRODUCT_DETAILS_TEST_CASES = [
  {
    key: 'find.frequently_bought_together',
    locales: {
      'en-GB': 'Frequently Bought Together',
      'nl-NL': 'Vaak samen gekocht met',
    },
  },
]
