const LOCATION_GB = 'location'
const LOCATION_NL = 'locatie'

const locations: {
  [K in 'en' | 'nl']: Record<number | 'many', string>
} = {
  en: {
    1: `${LOCATION_GB} across the UK`,
    many: `${LOCATION_GB}s across the UK`,
  },
  nl: {
    1: `${LOCATION_NL} locaties door heel Nederland`,
    many: `${LOCATION_NL}s door heel Nederland`,
  },
}

export const PRODUCT_LOCATIONS_TEXT = {
  'find.product_locations_heading': {
    'en-GB': 'Locations',
    'nl-NL': 'Locaties',
  },
  'find.no_locations_to_show': {
    'en-GB': 'No locations to show',
    'nl-NL': 'Geen locaties om te laten zien',
  },
  'find.product_locations_summary': {
    'en-GB': (numberOfLocations: number) =>
      `${numberOfLocations} ${
        locations.en[numberOfLocations] ?? locations.en.many
      }`,
    'nl-NL': (numberOfLocations: number) =>
      `${numberOfLocations} ${
        locations.nl[numberOfLocations] ?? locations.nl.many
      }`,
  },
}
