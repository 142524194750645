export const PRODUCT_SELLING_POINTS_TEXT = {
  'find.locations_title': {
    'en-GB': (numberOfLocations: number) =>
      numberOfLocations > 1 ? 'Locations' : 'Location',
    'nl-NL': (numberOfLocations: number) =>
      numberOfLocations > 1 ? 'Locaties' : 'Locatie',
  },
  'find.immediate_delivery': {
    'en-GB': 'Immediate Delivery Available',
    'nl-NL': 'Flexibele Datums',
  },
  'find.exchangeable': {
    'en-GB': 'Flexible Gift Choice',
    'nl-NL': 'TODO',
  },
}
