import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'

const cta: {
  [K in 'en' | 'nl']: Partial<Record<DepartmentsEnum, string>>
} = {
  en: {
    [DepartmentsEnum.DIGITAL_GIFTS]: 'Shop Experience Gifts',
    [DepartmentsEnum.DIGITAL_GIFT_CARDS]: 'Shop Gift Cards',
  },
  nl: {
    [DepartmentsEnum.DIGITAL_GIFTS]: 'TBD',
    [DepartmentsEnum.DIGITAL_GIFT_CARDS]: 'TBD',
  },
}

export const CARD_OPTIONS_TEXT = {
  'find.add_gift_to_a_card': {
    'en-GB': 'Add Gift to a Card',
    'nl-NL': 'Toevoegen aan een kaart',
  },
  'find.card_options_title': {
    'en-GB': 'Card Options | Moonpig',
    'nl-NL': 'Alle kaartopties | Greetz',
  },
  'find.your_gift': { 'en-GB': 'Your Gift', 'nl-NL': 'Je cadeau' },
  'find.free_card': { 'en-GB': 'Free Card', 'nl-NL': 'Gratis kaart' },
  'find.personalise_card': {
    'en-GB': 'Personalise Card',
    'nl-NL': 'Kaart personaliseren',
  },
  'find.continue_with_free_card': {
    'en-GB': 'Send by Post for Free',
    'nl-NL': 'Stuur Gratis per Post',
  },
  'find.card_options_heading': {
    'en-GB': (minimumPrice: string) =>
      `Or choose a different card from ${minimumPrice}`,
    'nl-NL': (minimumPrice: string) =>
      `Of kies een andere kaart vanaf ${minimumPrice}`,
  },
  'find.your_gift_comes_in_a_card_title': {
    'en-GB': 'Your Gift Comes in a Card!',
    'nl-NL': 'Je cadeau zit in een kaart!',
  },
  'find.your_gift_comes_in_a_card_subtitle': {
    'en-GB': 'Get a free one or select a paid card of your choice',
    'nl-NL': 'Krijg er één gratis of betaal voor een kaart naar je keuze',
  },
  'find.want_to_change_gift_title': {
    'en-GB': 'Want to change your gift?',
    'nl-NL': 'Een ander cadeau?',
  },
  'find.change_gift_in_card': {
    'en-GB': 'Change Gift in Card',
    'nl-NL': 'Cadeau in de kaart veranderen',
  },
  'find.card_options_unavailable': {
    'en-GB': 'Other card options are currently unavailable',
    'nl-NL': 'De andere kaartopties zijn op dit moment niet beschikbaar',
  },
  'find.something_went_wrong': {
    'en-GB': 'Something went wrong',
    'nl-NL': 'Er ging iets mis',
  },
  'find.its_not_you_its_us': {
    'en-GB': "It's not you, it's us!",
    'nl-NL': 'Het ligt niet aan jou, het ligt aan ons!',
  },
  'find.please_try_again': {
    'en-GB': 'Please try again',
    'nl-NL': 'Probeer het nog eens',
  },
  'find.continue_shopping_cta': {
    'en-GB': 'Continue Shopping',
    'nl-NL': 'TODO',
  },
  'find.digital_gift_redirect_cta': {
    'en-GB': (department: DepartmentsEnum) => `${cta.en[department]}`,
    'nl-NL': (department: DepartmentsEnum) => `${cta.nl[department]}`,
  },
  'find.existing_card_heading': {
    'en-GB': 'Add the gift to an existing card in your basket',
    'nl-NL': 'TODO',
  },
  'find.continue_with_existing_card': {
    'en-GB': 'Add Gift to Existing Card',
    'nl-NL': 'TODO',
  },
  'find.send_by_post_in_card': {
    'en-GB': 'Send by Post In a Card',
    'nl-NL': 'Per post versturen in een kaart',
  },
  'find.send_instantly_in_ecard': {
    'en-GB': 'Send Instantly In an eCard',
    'nl-NL': 'Direct versturen in een eCard',
  },
}
