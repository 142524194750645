import { daysBetween } from '@moonpig/web-shared-utils'

const createSpan = (colour: string) => ({
  start: `<span style=color:${colour}>`,
  end: `</span>`,
})

export const SHOP_FOR_REMINDER_TEXT = {
  'find.shop_for_reminder.sub_text': {
    'en-GB': 'Based on what other customers loved!',
    'nl-NL': 'Gebaseerd op wat andere klanten leuk vonden!',
  },
  'find.shop_for_reminder.days_until': {
    'en-GB': ({
      dateOfEvent,
      emphasisedTextColour,
    }: {
      dateOfEvent: Date
      emphasisedTextColour: string
    }) => {
      const daysUntilEvent = daysBetween(new Date(), dateOfEvent)
      const { start, end } = createSpan(emphasisedTextColour)

      if (daysUntilEvent === 1) {
        return `<br />is ${start}tomorrow${end}!`
      }

      if (daysUntilEvent === 0) {
        return `<br />is ${start}today${end}!`
      }

      return `in <br /> ${start}${daysUntilEvent} days${end}!`
    },
    'nl-NL': ({
      dateOfEvent,
      emphasisedTextColour,
    }: {
      dateOfEvent: Date
      emphasisedTextColour: string
    }) => {
      const daysUntilEvent = daysBetween(new Date(), dateOfEvent)
      const { start, end } = createSpan(emphasisedTextColour)

      if (daysUntilEvent === 1) {
        return `${start}morgen${end}!`
      }

      if (daysUntilEvent === 0) {
        return `${start}vandaag${end}!`
      }

      return `over <br /> ${start}${daysUntilEvent} dagen${end}!`
    },
  },
  'find.shop_for_reminder.cta': {
    'en-GB': ({ name }: { name: string }) => `Shop for ${name}`,
    'nl-NL': ({ name }: { name: string }) => `Shoppen voor ${name}`,
  },
  'find.shop_for_reminder.desktop_sub_text': {
    'en-GB': ({
      emphasisedTextColour,
      name,
    }: {
      emphasisedTextColour: string
      name: string
    }) => {
      const { start, end } = createSpan(emphasisedTextColour)

      return `We've ${start}<b>handpicked</b>${end} our best cards and gifts for ${name} to save you time!`
    },
    'nl-NL': ({
      emphasisedTextColour,
      name,
    }: {
      emphasisedTextColour: string
      name: string
    }) => {
      const { start, end } = createSpan(emphasisedTextColour)

      return `We hebben onze beste kaarten en cadeaus voor ${name} ${start}uitgekozen${end} om je tijd te besparen!`
    },
  },
}
