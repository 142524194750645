export const BUNDLES_TEXT = {
  'find.bundle.carousel.heading': {
    'en-GB': "Bestselling Father's Day Bundles",
    'nl-NL': 'Moederdag boeketten met een cadeau',
  },
  'find.bundle.carousel.description': {
    'en-GB':
      'Choosing the perfect card and gift for Dad has never been easier, treat him today!',
    'nl-NL':
      'Nu gratis moederdagkaart bij geselecteerde boeketten met cadeau. Groots verrassen doe je met Moederdag!',
  },
  'find.bundle.item_title': {
    'en-GB': (giftTitle: string) => {
      return `Custom card + ${giftTitle}`
    },
    'nl-NL': (giftTitle: string) => {
      return `Kaart + ${giftTitle}`
    },
  },
  'find.bundle.item_view_details': {
    'en-GB': 'View Bundle Details',
    'nl-NL': 'Meer Informatie',
  },
  'find.bundle.item_view_details_additional_info': {
    'en-GB': (productTitle: string) => `View ${productTitle} bundle details`,
    'nl-NL': (productTitle: string) =>
      `Meer informatie over ${productTitle}-pakket`,
  },
  'find.bundle.item_pill_favourite': {
    'en-GB': 'Favourite',
    'nl-NL': 'Favoriet',
  },
  'find.bundle.item_pill_selling-fast': {
    'en-GB': 'Selling fast',
    'nl-NL': 'Populair',
  },

  'find.bundle.item_pill_exclusive': {
    'en-GB': 'Exclusive',
    'nl-NL': 'Exclusief',
  },
  'find.bundle.item_pill_new': {
    'en-GB': 'New',
    'nl-NL': 'Nieuw',
  },
  'find.bundle.modal.content.title': {
    'en-GB': "What They'll Get",
    'nl-NL': 'Dit krijgen ze',
  },
  'find.bundle.modal.content.card': {
    'en-GB': 'The card',
    'nl-NL': 'De kaart',
  },
  'find.bundle.modal.content.card_subtext': {
    'en-GB': 'Choose your design and size next',
    'nl-NL': 'Kies je ontwerp en het formaat hierna',
  },
  'find.bundle.modal.content.gift': {
    'en-GB': 'The gift',
    'nl-NL': 'Het cadeau',
  },
  'find.bundle.modal.add_to_basket': {
    'en-GB': 'Select Your Card',
    'nl-NL': 'Kies je Kaart',
  },
  'find.bundle.modal.description': {
    'en-GB': (giftDescription: string) => {
      return (
        '<h3>The card</h3>' +
        '<p>Your chosen card, personalised by you especially for your recipient.</p>' +
        '<h3>The gift</h3>' +
        `<p>${giftDescription}</p>`
      )
    },
    'nl-NL': (giftDescription: string) => {
      return (
        '<h3>De kaart</h3>' +
        '<p>Kies je ontwerp en het formaat hierna.</p>' +
        '<h3>Het cadeau</h3>' +
        `<p>${giftDescription}</p>`
      )
    },
  },
}
