export const RATING_TEXT = {
  'find.rating_count': {
    'en-GB': (count: number) => `${Number(count).toLocaleString('en-GB')}`,
    'nl-NL': (count: number) => `${Number(count).toLocaleString('nl-NL')}`,
  },
  'find.total_reviews': {
    'en-GB': 'Total reviews',
    'nl-NL': 'Totaal aantal beoordelingen',
  },
}
