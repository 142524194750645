import { Occasion } from '@moonpig/web-explore-types-graphql'
import { OccasionsLocale } from '@moonpig/web-shared-reminders'

export const RECOMMENDATIONS_FOR_CUSTOMER_TEXT = {
  'find.upcoming_occasions': {
    'en-GB': 'Upcoming Occasions',
    'nl-NL': 'Aankomende gelegenheden',
  },
  'find.here_is_what_is_coming_up_in_the_next_30_days': {
    'en-GB': 'Here’s what’s coming up in the next 30 days.',
    'nl-NL': 'Dit zit eraan te komen in de eerstvolgende 30 dagen.',
  },
  'find.shop': {
    'en-GB': 'Shop',
    'nl-NL': 'Shoppen',
  },
  'find.birthday': {
    'en-GB': 'Birthday',
    'nl-NL': 'Verjaardag',
  },
  'find.special_occasion': {
    'en-GB': 'special occasion',
    'nl-NL': 'speciale gelegenheid',
  },
  'find.fathers_day': {
    'en-GB': 'Father’s Day',
    'nl-NL': 'Vaderdag',
  },
  'find.fathers_day_for_dads_everywhere': {
    'en-GB': 'Father’s Day for Dads Everywhere',
    'nl-NL': "Vaderdag voor alle papa's wereldwijd",
  },
  'find.christmas': {
    'en-GB': 'Christmas',
    'nl-NL': 'Kerstmis',
  },
  'find.mothers_day': {
    'en-GB': 'Mother’s Day',
    'nl-NL': 'Moederdag',
  },
  'find.valentines_day': {
    'en-GB': 'Valentine’s Day',
    'nl-NL': 'Valentijnsdag',
  },
  'find.anniversary': {
    'en-GB': 'Anniversary',
    'nl-NL': 'Jubileum',
  },
  'find.new_baby': {
    'en-GB': 'New Baby',
    'nl-NL': 'Geboorte',
  },
  'find.wedding': {
    'en-GB': 'Wedding',
    'nl-NL': 'Huwelijk',
  },
  'find.note': {
    'en-GB': 'Note',
    'nl-NL': '',
  },
  'find.based_on_what_other_customers_loved': {
    'en-GB': 'Based on what other customers loved.',
    'nl-NL': 'Geliefd bij onze klanten.',
  },
  'find.based_on_what_you_ordered_this_time_last_year': {
    'en-GB': 'Based on what you ordered this time last year',
    'nl-NL': 'Gebaseerd op wat je vorig jaar rond deze tijd hebt besteld',
  },
  'find.birthday_cards_we_think_you_will_love': {
    'en-GB': 'Birthday Cards We Think You’ll Love',
    'nl-NL': 'Verjaardagskaarten waar je blij van wordt',
  },
  'find.cards_for_occasion': {
    'en-GB': (c: { occasion: string }) => `Cards for ${c.occasion}`,
    'nl-NL': (c: { occasion: string }) =>
      c.occasion === 'speciale gelegenheid'
        ? `${c.occasion} kaarten`
        : `${c.occasion}kaarten`,
  },
  'find.recommended_for_you': {
    'en-GB': 'Recommended For You',
    'nl-NL': 'Aanbevolen Voor Jou',
  },
  'find.tomorrow': {
    'en-GB': 'Tomorrow',
    'nl-NL': 'Morgen',
  },
  'find.today': {
    'en-GB': 'Today',
    'nl-NL': 'Vandaag',
  },
  'find.in_n_days': {
    'en-GB': (c: { days: number }) => `In ${c.days} days`,
    'nl-NL': (c: { days: number }) => `Over ${c.days} dagen`,
  },
  'find.day_to_go': {
    'en-GB': 'day to go',
    'nl-NL': "dag en 't is zover",
  },
  'find.days_to_go': {
    'en-GB': 'days to go',
    'nl-NL': "dagen en 't is zover",
  },
  'find.see_recommendations': {
    'en-GB': 'See recommendations',
    'nl-NL': 'Bekijk de tips',
  },
  'find.options_under_development': {
    'en-GB':
      'This feature is under development, but we appreciate for your interest.',
    'nl-NL': '',
  },
  'find.shop_for_n_reminder': {
    'en-GB': 'Shop for your 1 reminder',
    'nl-NL': 'Zoek iets uit uit voor je moment',
  },
  'find.shop_for_n_reminders': {
    'en-GB': (c: { days: number }) => `Shop for your ${c.days} reminders`,
    'nl-NL': (c: { days: number }) =>
      `Zoek iets uit voor je ${c.days} momenten`,
  },
  occasion: {
    'en-GB': (occasion: Occasion) =>
      OccasionsLocale[occasion] ? OccasionsLocale[occasion]['en-GB'] : '',
    'nl-NL': (occasion: Occasion) =>
      OccasionsLocale[occasion] ? OccasionsLocale[occasion]['nl-NL'] : '',
  },
}
